<div [@animate]="{value:'*',params:{delay:'50ms',x:'-25px'}}">
    <div class="sp-flex sp-flex-row sp-w-full sp-mb-3">
        <div class="sp-text-2xl sp-text-sp-blue sp-w-auto">Credit Logs</div>
        <a class="sp-ml-auto" target="_blank" href="/account/export-credit-logs">
            <sp-button class="sp-block sp-mt-auto" icon="fa-duotone fa-file-csv">
                Download Credit Logs
            </sp-button>
        </a>
    </div>
    <table #table mat-table [dataSource]="logs" class="sp-mt-4 mat-elevation-z0">
        <ng-container matColumnDef="product">
            <th mat-header-cell *matHeaderCellDef> Product</th>
            <td mat-cell *matCellDef="let element">
              <div class="sp-flex sp-flex-col">
                <div>{{ element.product_name }}</div>
                <div class="!sp-text-xs sp-text-stone-500">
                  {{ element.purchase_date + "Z" | date : 'dd/MM/yyyy hh:mm:ss' }}
                </div>
              </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="credits">
            <th mat-header-cell *matHeaderCellDef>Credits</th>
            <td mat-cell *matCellDef="let element"> {{ element.total_credits }}</td>
        </ng-container>

        <ng-container matColumnDef="used">
            <th mat-header-cell *matHeaderCellDef>Used Credits</th>
            <td mat-cell *matCellDef="let element">
                <div class="sp-flex sp-flex-col">
                    <div>{{ element.used_credits }}</div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="remaining">
            <th mat-header-cell *matHeaderCellDef>Remaining Credits</th>
            <td mat-cell *matCellDef="let element">
                <div class="sp-flex sp-flex-col">
                    <div>{{ element.total_credits - element.used_credits }}</div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="expiry">
            <th mat-header-cell *matHeaderCellDef>Expiry Date</th>
            <td [matTooltip]="expired(element) ? 'This purchase has expired' : ''" [ngClass]="{ 'sp-text-red-600' : expired(element) }" mat-cell *matCellDef="let element">{{ element.expiry + "Z" | date : 'dd/MM/yyyy hh:mm:ss' }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="usageColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: usageColumns;"></tr>
    </table>
    @if (totalItemCount > 0) {
        <mat-paginator class="my-4" [length]="totalItemCount"
                       [pageSize]="perPage" (page)="toPage($event.pageIndex)">
        </mat-paginator>
    }
</div>
