<div class="sp-w-full" [@animate]="{value:'*',params:{delay:'50ms',x:'-25px'}}">

  <div class="sp-w-full sp-flex sp-flex-row sp-items-center dense sp-mb-4">
    <div class="sp-text-2xl sp-text-sp-blue sp-w-auto">Download Logs</div>
    <mat-form-field subscriptSizing="dynamic" class="sp-ml-auto sp-w-[320px]" appearance="outline">
      <mat-label>Search</mat-label>
      <input [formControl]="searchControl" matInput type="search">
    </mat-form-field>
  </div>
  <table #table mat-table [dataSource]="items" class="sp-mt-4 mat-elevation-z0">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef> Username </th>
      <td mat-cell *matCellDef="let element"> {{element.username}} </td>
    </ng-container>

    <ng-container matColumnDef="job_ref">
      <th mat-header-cell *matHeaderCellDef> Job Reference </th>
      <td mat-cell *matCellDef="let element"> {{element.job_ref}} </td>
    </ng-container>

    <ng-container matColumnDef="download_date">
      <th mat-header-cell *matHeaderCellDef> Download Date </th>
      <td mat-cell *matCellDef="let element"> {{element.date + "Z" | date: 'dd/MM/yyyy hh:mm:ss'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  @if(totalItemCount > 0) {
    <mat-paginator class="my-4" [length]="totalItemCount"
                   [pageSize]="limit" (page)="toPage($event.pageIndex + 1)">
    </mat-paginator>
  }
</div>
